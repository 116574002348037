import {React, lazy} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Home';
import reportWebVitals from './reportWebVitals';
import { HashRouter, BrowserRouter, Routes, Route } from "react-router-dom";

//const Test = lazy(() => import('./Test'));

import Test from './Test';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path='/test' element={<Test />} />
        </Route>
      </Routes>
    </HashRouter>
);

//<React.StrictMode>
//<App />
//</React.StrictMode>
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
